<template>
  <div class='page-box'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="姓名" prop="nickName">
          <a-input v-model="queryRef.nickName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="loginName">
          <a-input v-model="queryRef.loginName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="支付时间" prop="dateRange">
          <a-range-picker v-model='queryRef.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class='info-box'>
      <div class='info-item' v-for='(item,index) in infoData' :key='index'>{{item.name}}：{{item.value}}</div>
    </div>
    <a-table :rowKey="(record,index) => index" :loading='loading' :columns="columns" :data-source="tableData" :pagination="pagination" @change='changePage'>
    </a-table>
  </div>

</template>

<script>
import { agent } from '@/api'
export default {
  data: () => ({
    queryRef: {
      dateRange: [],
      nickName: '',
      loginName: ''
    },
    infoData: null,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      size: 'small',
      total: 0,
    },
    columns: [
      {
        title: '用户名',
        dataIndex: 'nickName',
        scopedSlots: { customRender: 'nickName' },
      },
      {
        title: '手机号',
        dataIndex: 'loginName',
        scopedSlots: { customRender: 'loginName' },
      },
      {
        title: '报单和复投单数',
        dataIndex: 'repurchaseCount',
      },
      {
        title: '报单和复投金额',
        dataIndex: 'repurchaseAmount',
      },
      {
        title: '复购数',
        dataIndex: 'repurchaseNoCount',
      },
      {
        title: '复购金额',
        dataIndex: 'repurchaseNoAmount',
      },
      {
        title: '合计单数',
        dataIndex: 'totalCount',
      },
      {
        title: '合计金额',
        dataIndex: 'totalAmount',
      }
    ],
  }),
  methods: {
    changePage (e) {
      this.pagination = e
      this.getTableData()
      this.getInfo()
    },
    handleSearch () {
      this.getTableData()
      this.getInfo()
    },
    handleReset () {
      this.$refs.formRef.resetFields()
      this.getTableData()
      this.getInfo()
    },
    async getInfo () {
      let beginDate = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[0] : ''
      let endDate = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[1] : ''
      let param = {
        ...this.queryRef,
        beginDate: beginDate,
        endDate: endDate
      }
      delete param.dateRange
      const { data, code } = await agent.getAgentSalesTotal(param)
      if (code === '00000') {
        this.infoData = data
      }
    },
    async getTableData () {
      this.loading = true
      let beginDate = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[0] : ''
      let endDate = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[1] : ''
      let param = {
        ...this.queryRef,
        beginDate: beginDate,
        endDate: endDate,
        current: this.pagination.current,
        size: this.pagination.pageSize,
      }
      delete param.dateRange
      const { code, page, data, msg } = await agent.getAgentSales(param)
      this.loading = false
      if (code === '00000') {
        this.tableData = data
        this.pagination.current = page.pageNo
        this.pagination.pageSize = page.pageSize
        this.pagination.total = page.total
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
  },
  mounted () {
    this.getTableData()
    this.getInfo()
  },
}
</script>

<style lang="less" scoped>
.info-box {
  display: flex;
  align-items: center;
  margin: 12px;
  .info-item {
    margin-right: 48px;
  }
}
.flex-a-c {
  display: flex;
  align-items: center;
}
</style>
